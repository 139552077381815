<template>
  <div class="conversations-container pt-0">
    <div class="ejar__contract">
      <div class="ejar__contract--slider">
        <b-carousel id="carousel-1" v-model="slide" :interval="4000" controls indicators background="#ababab"
          img-width="1024" img-height="280" style="text-shadow: 1px 1px 2px #333;" @sliding-start="onSlideStart"
          @sliding-end="onSlideEnd">
          <!-- Text slides with image -->
          <b-carousel-slide caption="الخدمات" text="توثيق عقد سكني"
            img-src="https://images.pexels.com/photos/3288103/pexels-photo-3288103.png?auto=compress&cs=tinysrgb&w=1600">
          </b-carousel-slide>
          <!-- Slides with custom text -->
          <b-carousel-slide
            img-src="https://img.freepik.com/free-photo/house-isolated-field_1303-23773.jpg?w=1380&t=st=1668682072~exp=1668682672~hmac=c4215e6f88168109040db3e0165ea6f36c89d8529083ff9214584a9bfddb17f3">
            <h1>الخدمات 2</h1>
          </b-carousel-slide>
          <!-- Slides with image only -->
          <b-carousel-slide
            img-src="https://img.freepik.com/free-photo/new-york-city-ny-usa-october-20-2020-vessel-hudson-yards-staircase-designed-by-architect-thomas-heatherwick-midtown-manhattan-west_1321-2481.jpg?w=1380&t=st=1668682084~exp=1668682684~hmac=2621f79a727149d72108e9b2fb4b6ffce1e3c46dc7ca89760eda6cbb26ab1d00">
          </b-carousel-slide>
          <!-- Slides with img slot -->
          <!-- Note the classes .d-block and .img-fluid to prevent browser default image alignment -->
          <b-carousel-slide>
            <template #img>
              <img class="d-block img-fluid w-100" width="1024" height="280"
                src="https://images.pexels.com/photos/1732414/pexels-photo-1732414.jpeg?auto=compress&cs=tinysrgb&w=1600"
                alt="image slot">
            </template>
          </b-carousel-slide>
          <!-- Slide with blank fluid image to maintain slide aspect ratio -->
          <b-carousel-slide caption=" 3 الخدمات"
            img-src="https://images.pexels.com/photos/1475938/pexels-photo-1475938.jpeg?auto=compress&cs=tinysrgb&w=1600"
            img-alt="Blank image">
            <p>
              توثيق عقد سكني 3 </p>
          </b-carousel-slide>
        </b-carousel>
        <!-- <p class="mt-4">
          Slide #: {{ slide }}<br>
          Sliding: {{ sliding }}
        </p> -->
      </div>
      <div class="container">
        <div class="ejar__contract--multi-steps" v-if="!isActive">
          <!-- Tabs with card integration -->
          <b-card no-body>
            <b-tabs v-model="tabIndex" small card>
              <b-tab title="مدة العقد ">
                <b-card>
                  <div class="date__picker--row row">
                    <div class="col-md-4 column">
                      <label for="date">نوع العقد</label>
                      <b-form-select v-model="selected" class="mb-0 select-dropdown" no-caret>
                        <!-- This slot appears above the options from 'options' prop -->
                        <template #first>
                          <b-form-select-option :value="null" disabled no-caret>عقد سكني</b-form-select-option>
                        </template>
                        <!-- These options will appear after the ones from 'options' prop -->
                        <b-form-select-option value="C">عقد سكني</b-form-select-option>
                        <b-form-select-option value="D">عقد تجاري </b-form-select-option>
                      </b-form-select>
                    </div>
                    <!-- col sm 4 end 1  -->
                    <div class="col-md-4">
                      <div>
                        <label for="datepicker-placeholder">تاريخ البدء</label>
                        <b-form-datepicker id="datepicker-placeholder" placeholder="ادخل بداية فترة العقد" locale="en">
                        </b-form-datepicker>
                      </div>
                    </div>
                    <!-- col sm 4 end 2  -->
                    <div class="col-md-4">
                      <div>
                        <label for="datepicker-placeholder2"> تاريخ الإنتهاء</label>
                        <b-form-datepicker id="datepicker-placeholder2" placeholder="ادخل نهاية فترة العقد" locale="en">
                        </b-form-datepicker>
                      </div>
                    </div>
                    <!-- col sm 4 end 3 -->
                  </div>
                  <!-- row  end-->
                  <div class="row">
                    <div class="col-md-12">
                      <div class="date__picker--div next__btn--div pt-4 text-left">
                        <button class="next__btn--button" @click="tabIndex++">التالي</button>
                      </div>
                    </div>
                    <!-- col sm 12 end -->
                  </div>
                </b-card>
                <div class="date__picker--note">
                  <p class="note">
                    تنبيه : مدة العقد تحدد سعر التوثيق , و سيتم دفع المقابل المالي لخدمة توثيق العقد في اخر خطوة.
                  </p>
                </div>
              </b-tab>
              <!-- tab 1 end  -->
              <!-- tab 2 العقارات و الوحدات" starts -->
              <b-tab title="العقارات و الوحدات">
                <b-card>
                  <div class="already--property" v-if="!addProperty">
                    <div class="date__picker--row tenant--escorts--row row">
                      <div class="col-md-4">
                        <label for="date">الغرض من العقار</label>
                        <b-form-select v-model="selected" class="mb-0 select-dropdown" no-caret>
                          <!-- This slot appears above the options from 'options' prop -->
                          <template #first>
                            <b-form-select-option :value="null" disabled no-caret>الغرض من العقار</b-form-select-option>
                          </template>
                          <!-- These options will appear after the ones from 'options' prop -->
                          <b-form-select-option value="C">عقد سكني</b-form-select-option>
                          <b-form-select-option value="D">عقد تجاري </b-form-select-option>
                        </b-form-select>
                      </div>
                      <!-- col sm 4 end 1  -->
                      <div class="col-md-4">
                        <label for="date">نوع العقار</label>
                        <b-form-select v-model="selected" class="mb-0 select-dropdown" no-caret>
                          <!-- This slot appears above the options from 'options' prop -->
                          <template #first>
                            <b-form-select-option :value="null" disabled no-caret>نوع العقار</b-form-select-option>
                          </template>
                          <!-- These options will appear after the ones from 'options' prop -->
                          <b-form-select-option value="C">عقد سكني</b-form-select-option>
                          <b-form-select-option value="D">عقد تجاري </b-form-select-option>
                        </b-form-select>
                      </div>
                      <!-- col sm 4 end 2  -->
                      <div class="col-md-4">
                        <label for="date">نوع الوثيقة</label>
                        <b-form-select v-model="selected" class="mb-0 select-dropdown" no-caret>
                          <!-- This slot appears above the options from 'options' prop -->
                          <template #first>
                            <b-form-select-option :value="null" disabled no-caret>نوع الوثيقة</b-form-select-option>
                          </template>
                          <!-- These options will appear after the ones from 'options' prop -->
                          <b-form-select-option value="C">عقد سكني</b-form-select-option>
                          <b-form-select-option value="D">عقد تجاري </b-form-select-option>
                        </b-form-select>
                      </div>
                      <!-- col sm 4 end 3 -->
                      <div class="col-md-4">
                        <label for="date">رقم الوثيقة</label>
                        <b-form-input v-model="text" placeholder="12155412"></b-form-input>
                      </div>
                      <!-- col sm 4 end 4 -->
                    </div>
                    <!-- row  end-->
                    <div class="slick__slider--row">
                      <div class="slick__slider--div">
                        <slick ref="slickSlider" :options="slickOptions">
                          <div class="slick__slider--cards d-flex">
                            <div class="image--div">
                              <div class="inner-carousel">
                                <b-carousel id="carousel-1" v-model="slide3" :interval="4000" controls
                                  background="#ababab" style="text-shadow: 1px 1px 2px #333;"
                                  @sliding-start="onSlideStart" @sliding-end="onSlideEnd">
                                  <!-- Text slides with image -->
                                  <b-carousel-slide
                                    img-src="https://images.pexels.com/photos/3288103/pexels-photo-3288103.png?auto=compress&cs=tinysrgb&w=1600">
                                  </b-carousel-slide>
                                  <!-- Slides with custom text -->
                                  <b-carousel-slide img-src="https://picsum.photos/1024/480/?image=54">
                                  </b-carousel-slide>
                                  <!-- Slides with image only -->
                                  <b-carousel-slide img-src="https://picsum.photos/1024/480/?image=58">
                                  </b-carousel-slide>
                                  <!-- Slides with img slot -->
                                  <!-- Note the classes .d-block and .img-fluid to prevent browser default image alignment -->
                                  <b-carousel-slide>
                                    <template #img>
                                      <img class="d-block img-fluid w-100"
                                        src="https://picsum.photos/1024/480/?image=55" alt="image slot">
                                    </template>
                                  </b-carousel-slide>
                                  <!-- Slide with blank fluid image to maintain slide aspect ratio -->
                                </b-carousel>
                              </div>
                            </div>
                            <!-- image--div -->
                            <div class="content--div d-flex justify-content-between w-100 pr-3">
                              <div class="">
                                <div class="first__party">
                                  <div class="first__party--title"><span class="first__span">الإسم </span></div>
                                  <div class="first__party--name"><span class="second__span"> شقة للإيجار بحي الرياض
                                    </span></div>
                                </div>
                                <div class="first__party">
                                  <div class="first__party--title"><span class="first__span">العنوان</span></div>
                                  <div class="first__party--name"><span class="second__span"> ٥٤ شارع جرير، الملز،
                                      الرياض..</span></div>
                                </div>
                              </div>
                              <div class="">
                                <div class="first__party">
                                  <div class="first__party--title"><span class="first__span">نوع العقار </span></div>
                                  <div class="first__party--name"><span class="second__span">شقة</span></div>
                                </div>
                                <div class="first__party">
                                  <div class="first__party--title"><span class="first__span">رقم العقار</span></div>
                                  <div class="first__party--name"><span class="second__span"> ٢١ </span></div>
                                </div>
                              </div>
                            </div>
                            <!-- content--div -->
                          </div>
                          <div class="slick__slider--cards d-flex">
                            <div class="image--div">
                              <div class="inner-carousel">
                                <b-carousel id="carousel-1" v-model="slide4" :interval="4000" controls
                                  background="#ababab" @sliding-start="onSlideStart" @sliding-end="onSlideEnd">
                                  <!-- Text slides with image -->
                                  <b-carousel-slide img-src="https://picsum.photos/1024/480/?image=54">
                                  </b-carousel-slide>
                                  <!-- Slides with custom text -->
                                  <b-carousel-slide img-src="">
                                  </b-carousel-slide>
                                  <!-- Slides with image only -->
                                  <b-carousel-slide
                                    img-src="https://img.freepik.com/free-photo/house-isolated-field_1303-23773.jpg?w=1380&t=st=1668682072~exp=1668682672~hmac=c4215e6f88168109040db3e0165ea6f36c89d8529083ff9214584a9bfddb17f3">
                                  </b-carousel-slide>
                                  <!-- Slides with img slot -->
                                  <!-- Note the classes .d-block and .img-fluid to prevent browser default image alignment -->
                                  <b-carousel-slide>
                                    <template #img>
                                      <img class="d-block img-fluid w-100"
                                        src="https://picsum.photos/1024/480/?image=55" alt="image slot">
                                    </template>
                                  </b-carousel-slide>
                                  <!-- Slide with blank fluid image to maintain slide aspect ratio -->
                                </b-carousel>
                              </div>
                            </div>
                            <!-- image--div -->
                            <div class="content--div d-flex justify-content-between w-100 pr-3">
                              <div class="">
                                <div class="first__party">
                                  <div class="first__party--title"><span class="first__span">الإسم </span></div>
                                  <div class="first__party--name"><span class="second__span"> شقة للإيجار بحي الرياض
                                    </span></div>
                                </div>
                                <div class="first__party">
                                  <div class="first__party--title"><span class="first__span">العنوان</span></div>
                                  <div class="first__party--name"><span class="second__span"> ٥٤ شارع جرير، الملز،
                                      الرياض..</span></div>
                                </div>
                              </div>
                              <div class="">
                                <div class="first__party">
                                  <div class="first__party--title"><span class="first__span">نوع العقار </span></div>
                                  <div class="first__party--name"><span class="second__span">شقة</span></div>
                                </div>
                                <div class="first__party">
                                  <div class="first__party--title"><span class="first__span">رقم العقار</span></div>
                                  <div class="first__party--name"><span class="second__span"> ٢١ </span></div>
                                </div>
                              </div>
                            </div>
                            <!-- content--div -->
                          </div>
                          <div class="slick__slider--cards d-flex">
                            <div class="image--div">
                              <div class="inner-carousel">
                                <b-carousel id="carousel-1" v-model="slide2" :interval="4000" controls
                                  background="#ababab" style="text-shadow: 1px 1px 2px #333;"
                                  @sliding-start="onSlideStart" @sliding-end="onSlideEnd">
                                  <!-- Text slides with image -->
                                  <b-carousel-slide
                                    img-src="https://img.freepik.com/free-photo/house-isolated-field_1303-23773.jpg?w=1380&t=st=1668682072~exp=1668682672~hmac=c4215e6f88168109040db3e0165ea6f36c89d8529083ff9214584a9bfddb17f3">
                                  </b-carousel-slide>
                                  <!-- Slides with custom text -->
                                  <b-carousel-slide
                                    img-src="https://img.freepik.com/free-photo/new-york-city-ny-usa-october-20-2020-vessel-hudson-yards-staircase-designed-by-architect-thomas-heatherwick-midtown-manhattan-west_1321-2481.jpg?w=1380&t=st=1668682084~exp=1668682684~hmac=2621f79a727149d72108e9b2fb4b6ffce1e3c46dc7ca89760eda6cbb26ab1d00">
                                  </b-carousel-slide>
                                  <!-- Slides with image only -->
                                  <b-carousel-slide img-src="https://picsum.photos/1024/480/?image=58">
                                  </b-carousel-slide>
                                  <!-- Slides with img slot -->
                                  <!-- Note the classes .d-block and .img-fluid to prevent browser default image alignment -->
                                  <b-carousel-slide>
                                    <template #img>
                                      <img class="d-block img-fluid w-100"
                                        src="https://picsum.photos/1024/480/?image=55" alt="image slot">
                                    </template>
                                  </b-carousel-slide>
                                  <!-- Slide with blank fluid image to maintain slide aspect ratio -->
                                </b-carousel>
                              </div>
                            </div>
                            <!-- image--div -->
                            <div class="content--div d-flex justify-content-between w-100 pr-3">
                              <div class="">
                                <div class="first__party">
                                  <div class="first__party--title"><span class="first__span">الإسم </span></div>
                                  <div class="first__party--name"><span class="second__span"> شقة للإيجار بحي الرياض
                                    </span></div>
                                </div>
                                <div class="first__party">
                                  <div class="first__party--title"><span class="first__span">العنوان</span></div>
                                  <div class="first__party--name"><span class="second__span"> ٥٤ شارع جرير، الملز،
                                      الرياض..</span></div>
                                </div>
                              </div>
                              <div class="">
                                <div class="first__party">
                                  <div class="first__party--title"><span class="first__span">نوع العقار </span></div>
                                  <div class="first__party--name"><span class="second__span">شقة</span></div>
                                </div>
                                <div class="first__party">
                                  <div class="first__party--title"><span class="first__span">رقم العقار</span></div>
                                  <div class="first__party--name"><span class="second__span"> ٢١ </span></div>
                                </div>
                              </div>
                            </div>
                            <!-- content--div -->
                          </div>
                          <div class="slick__slider--cards d-flex">
                            <div class="image--div">
                              <div class="inner-carousel">
                                <b-carousel id="carousel-1" v-model="slide4" :interval="4000" controls
                                  background="#ababab" @sliding-start="onSlideStart" @sliding-end="onSlideEnd">
                                  <!-- Text slides with image -->
                                  <b-carousel-slide
                                    img-src="https://images.pexels.com/photos/1732414/pexels-photo-1732414.jpeg?auto=compress&cs=tinysrgb&w=1600">
                                  </b-carousel-slide>
                                  <!-- Slides with custom text -->
                                  <b-carousel-slide
                                    img-src="https://img.freepik.com/free-photo/new-york-city-ny-usa-october-20-2020-vessel-hudson-yards-staircase-designed-by-architect-thomas-heatherwick-midtown-manhattan-west_1321-2481.jpg?w=1380&t=st=1668682084~exp=1668682684~hmac=2621f79a727149d72108e9b2fb4b6ffce1e3c46dc7ca89760eda6cbb26ab1d00">
                                  </b-carousel-slide>
                                  <!-- Slides with image only -->
                                  <b-carousel-slide
                                    img-src="https://img.freepik.com/free-photo/house-isolated-field_1303-23773.jpg?w=1380&t=st=1668682072~exp=1668682672~hmac=c4215e6f88168109040db3e0165ea6f36c89d8529083ff9214584a9bfddb17f3">
                                  </b-carousel-slide>
                                  <!-- Slides with img slot -->
                                  <!-- Note the classes .d-block and .img-fluid to prevent browser default image alignment -->
                                  <b-carousel-slide>
                                    <template #img>
                                      <img class="d-block img-fluid w-100"
                                        src="https://picsum.photos/1024/480/?image=55" alt="image slot">
                                    </template>
                                  </b-carousel-slide>
                                  <!-- Slide with blank fluid image to maintain slide aspect ratio -->
                                </b-carousel>
                              </div>
                            </div>
                            <!-- image--div -->
                            <div class="content--div d-flex justify-content-between w-100 pr-3">
                              <div class="">
                                <div class="first__party">
                                  <div class="first__party--title"><span class="first__span">الإسم </span></div>
                                  <div class="first__party--name"><span class="second__span"> شقة للإيجار بحي الرياض
                                    </span></div>
                                </div>
                                <div class="first__party">
                                  <div class="first__party--title"><span class="first__span">العنوان</span></div>
                                  <div class="first__party--name"><span class="second__span"> ٥٤ شارع جرير، الملز،
                                      الرياض..</span></div>
                                </div>
                              </div>
                              <div class="">
                                <div class="first__party">
                                  <div class="first__party--title"><span class="first__span">نوع العقار </span></div>
                                  <div class="first__party--name"><span class="second__span">شقة</span></div>
                                </div>
                                <div class="first__party">
                                  <div class="first__party--title"><span class="first__span">رقم العقار</span></div>
                                  <div class="first__party--name"><span class="second__span"> ٢١ </span></div>
                                </div>
                              </div>
                            </div>
                            <!-- content--div -->
                          </div>
                          <div class="slick__slider--cards d-flex">
                            <div class="image--div">
                              <div class="inner-carousel">
                                <b-carousel id="carousel-1" v-model="slide5" :interval="4000" controls
                                  background="#ababab" @sliding-start="onSlideStart" @sliding-end="onSlideEnd">
                                  <!-- Text slides with image -->
                                  <b-carousel-slide
                                    img-src="https://images.pexels.com/photos/1475938/pexels-photo-1475938.jpeg?auto=compress&cs=tinysrgb&w=1600">
                                  </b-carousel-slide>
                                  <!-- Slides with custom text -->
                                  <b-carousel-slide
                                    img-src="https://img.freepik.com/free-photo/new-york-city-ny-usa-october-20-2020-vessel-hudson-yards-staircase-designed-by-architect-thomas-heatherwick-midtown-manhattan-west_1321-2481.jpg?w=1380&t=st=1668682084~exp=1668682684~hmac=2621f79a727149d72108e9b2fb4b6ffce1e3c46dc7ca89760eda6cbb26ab1d00">
                                  </b-carousel-slide>
                                  <!-- Slides with image only -->
                                  <b-carousel-slide
                                    img-src="https://img.freepik.com/free-photo/house-isolated-field_1303-23773.jpg?w=1380&t=st=1668682072~exp=1668682672~hmac=c4215e6f88168109040db3e0165ea6f36c89d8529083ff9214584a9bfddb17f3">
                                  </b-carousel-slide>
                                  <!-- Slides with img slot -->
                                  <!-- Note the classes .d-block and .img-fluid to prevent browser default image alignment -->
                                  <b-carousel-slide>
                                    <template #img>
                                      <img class="d-block img-fluid w-100"
                                        src="https://images.pexels.com/photos/1732414/pexels-photo-1732414.jpeg?auto=compress&cs=tinysrgb&w=1600"
                                        alt="image slot">
                                    </template>
                                  </b-carousel-slide>
                                  <!-- Slide with blank fluid image to maintain slide aspect ratio -->
                                </b-carousel>
                              </div>
                            </div>
                            <!-- image--div -->
                            <div class="content--div d-flex justify-content-between w-100 pr-3">
                              <div class="">
                                <div class="first__party">
                                  <div class="first__party--title"><span class="first__span">الإسم </span></div>
                                  <div class="first__party--name"><span class="second__span"> شقة للإيجار بحي الرياض
                                    </span></div>
                                </div>
                                <div class="first__party">
                                  <div class="first__party--title"><span class="first__span">العنوان</span></div>
                                  <div class="first__party--name"><span class="second__span"> ٥٤ شارع جرير، الملز،
                                      الرياض..</span></div>
                                </div>
                              </div>
                              <div class="">
                                <div class="first__party">
                                  <div class="first__party--title"><span class="first__span">نوع العقار </span></div>
                                  <div class="first__party--name"><span class="second__span">شقة</span></div>
                                </div>
                                <div class="first__party">
                                  <div class="first__party--title"><span class="first__span">رقم العقار</span></div>
                                  <div class="first__party--name"><span class="second__span"> ٢١ </span></div>
                                </div>
                              </div>
                            </div>
                            <!-- content--div -->
                          </div>
                        </slick>
                      </div>
                      <!-- slick__slider--div  -->
                    </div>
                    <!-- slick__slider--row -->
                    <div class="d-inline-flex align-items-center w-100 pt-0 pt-sm-4 row">
                      <div class="col-md-6 pb-3 pb-sm-0">
                        <div class="d-flex align-items-center">
                          <div class="register__new--property">
                            <p class="descp pt-2">
                              عقار ليس مسجل ؟ <span class="green--color cursor-pointer"
                                v-on:click="addProperty = !addProperty">سجل عقار جديد</span>
                            </p>
                          </div>
                          <!-- register__new--property -->
                        </div>
                      </div>
                      <!-- col sm 6 ends 1  -->
                      <div class="col-md-6 mobile--p-0">
                        <div class="next__btn--div d-flex align-items-center justify-content-end pl-3">
                          <button class="next__btn--button back--button ml-3" @click="tabIndex--">العودة</button>
                          <button class="next__btn--button" @click="tabIndex++">التالي</button>
                        </div>
                        <!-- col sm 6 ends 2  -->
                      </div>
                    </div>
                    <!-- row ends 2 -->
                  </div>
                  <!-- already--property -->
                  <div class="register--new--property" v-else>
                    <div class="d-inline-flex align-items-center w-100 row">
                      <div class="col-md-6">
                        <div class="d-flex align-items-center">
                          <div class="register__new--property">
                            <h5 class="title">
                              تسجيل عقار جديد
                            </h5>
                            <p class="descp pt-2 pb-4">
                              عقار ليس مسجل ؟ <span class="green--color cursor-pointer"
                                v-on:click="addProperty = !addProperty"> تسجيل برقم الوثيقة</span>
                            </p>
                          </div>
                          <!-- register__new--property -->
                        </div>
                      </div>
                      <!-- col sm 4 ends 2  -->
                    </div>
                    <!-- row ends 1 -->
                    <!-- add property starts  -->
                    <div class="container">
                      <div class="add__property--div">
                        <div class="accordion" role="tablist">
                          <b-card no-body class="mb-1">
                            <b-card-header header-tag="header" class="p-1" role="tab">
                              <b-button block v-b-toggle.accordion-1 variant="info"> وثائق الملكية <strong
                                  v-if="isVisible" aria-hidden="true" class="arrow--icon hi-angle-up icon"></strong>
                                <strong v-else aria-hidden="true" class="arrow--icon hi-angle-down icon"></strong>
                              </b-button>
                            </b-card-header>
                            <b-collapse id="accordion-1" visible accordion="my-accordion" role="tabpanel"
                              v-model="isVisible">
                              <b-card-body>
                                <b-card-text>
                                  <div class="custom--row row">
                                    <div class="col-md-4">
                                      <label for="date">نوع وثيقة الملكية</label>
                                      <b-form-select v-model="selected" :options="options" class="select-dropdown"
                                        no-caret>
                                        <!-- This slot appears above the options from 'options' prop -->
                                        <template #first>
                                          <b-form-select-option :value="null" disabled no-caret>صك ملكية إلكتروني
                                          </b-form-select-option>
                                        </template>
                                        <!-- These options will appear after the ones from 'options' prop -->
                                        <b-form-select-option value="C">Option C</b-form-select-option>
                                        <b-form-select-option value="D">Option D</b-form-select-option>
                                      </b-form-select>
                                    </div>
                                    <!-- col sm 4 ends 1  -->
                                    <div class="col-md-4">
                                      <div>
                                        <label for="datepicker-placeholder3">تاريخ الإصدار</label>
                                        <b-form-datepicker id="datepicker-placeholder3" placeholder="تاريخ الإصدار"
                                          locale="en">
                                        </b-form-datepicker>
                                      </div>
                                    </div>
                                    <!-- col sm 4 ends 2  -->
                                    <div class="col-md-4">
                                      <div>
                                        <label for="amount">رقم وثيقة المليكة</label>
                                        <b-form-input v-model="text" placeholder="رقم وثيقة المليكة"></b-form-input>
                                      </div>
                                    </div>
                                    <!-- col sm 4 ends 3  -->
                                    <!-- <div class="col-md-4">
                      <div>
                        <label for="amount">رقم وثيقة المليكة</label>
                        <b-form-input v-model="text" placeholder="رقم وثيقة المليكة"></b-form-input>
                      </div>
                    </div> -->
                                  </div>
                                  <!-- row ends  -->
                                  <!-- upload file row  starts -->
                                  <div class="upload__file">
                                    <!-- Styled -->
                                    <label for="date">المرفقات</label>
                                    <div class="upload__file--div">
                                      <b-form-file v-model="file1" :state="Boolean(file1)"
                                        placeholder="Choose a file or drop it here..."
                                        drop-placeholder="Drop file here..."></b-form-file>
                                      <span class="upload__file--text">
                                        <span class="upload--icon">
                                          <img class="img-fluid" src="../../assets/images/contracts/upload-icon.svg"
                                            alt="Upload Icon">
                                        </span>
                                        <h6 class="pt-4 pb-2">
                                          تحميل مرفقات جديدة
                                        </h6>
                                        <p>يمكنك إضافة المرفقات بسحبها هنا أو يمكنك تصفح الملفات من جهازك من هنا</p>
                                      </span>
                                    </div>
                                    <!-- upload__file--div -->
                                    <div class="mt-3 uploaded--files">
                                      <div class="uploaded--file">
                                        <span class="uploaded--doc--icon">
                                          <img class="img-fluid"
                                            src="../../assets/images/contracts/uploaded-doc-icon.svg"
                                            alt="Uploaded Doc Icon">
                                        </span>
                                        <span class="uploaded--doc--name"> صك ملكية إلكتروني.PDF <span
                                            class="uploaded-file-size">232kb</span></span>
                                        <span class="action-btn">
                                          <span class="download--icon hi-arrow-down1 icon download-icon"></span>
                                          <span class="download--icon hi-trash icon delete-icon"></span>
                                        </span>
                                      </div>
                                      <div class="uploaded--file">
                                        <span class="uploaded--doc--icon">
                                          <img class="img-fluid"
                                            src="../../assets/images/contracts/uploaded-doc-icon.svg"
                                            alt="Uploaded Doc Icon">
                                        </span>
                                        <span class="uploaded--doc--name">{{ file1 ? file1.name : '' }} <span
                                            class="uploaded-file-size">{{file1 ? file1.size : ''}} </span></span>
                                        <span class="action-btn">
                                          <span class="download--icon hi-arrow-down1 icon download-icon"></span>
                                          <span class="download--icon hi-trash icon delete-icon"></span>
                                        </span>
                                      </div>
                                      <!-- <div class="uploaded--file"> Selected file: {{ file1 ? file1.name : '' }} </div> -->
                                    </div>
                                  </div>
                                  <!-- upload file row  ends -->
                                </b-card-text>
                                <!-- accordian 1 card  -->
                              </b-card-body>
                            </b-collapse>
                            <!-- accordion-1 -->
                          </b-card>
                          <!-- B CARD 1 -->
                          <b-card no-body class="mb-1 address--accordian">
                            <b-card-header header-tag="header" class="p-1" role="tab">
                              <b-button block v-b-toggle.accordion-2 variant="info">ملاك العقار <strong v-if="isVisible"
                                  aria-hidden="true" class="arrow--icon hi-angle-up icon"></strong>
                                <strong v-else aria-hidden="true" class="arrow--icon hi-angle-down icon"></strong>
                              </b-button>
                            </b-card-header>
                            <b-collapse id="accordion-2" accordion="my-accordion" role="tabpanel">
                              <b-card-body class="pt-0">
                                <b-card-text>
                                  <div class="property__owner--data">
                                    <div class="row">
                                      <div class="col-md-6">
                                        <div class="property__owner--title">
                                          <h6>
                                            إضافة مالك العقار سواء كان فرد أو مؤسسة
                                          </h6>
                                        </div>
                                        <!-- property__owner--title -->
                                        <div class="owner__empty--data owner__data">
                                          <div class="property__owner--img">
                                            <img class="img-fluid userIcon"
                                              src="../../assets/images/contracts/user-icon.svg" alt="User Icon">
                                          </div>
                                          <div class="property__owner--text">
                                            <h4>لا يوجد ملاك حاليين</h4>
                                          </div>
                                        </div>
                                        <!-- owner__empty--data end -->
                                        <div class="owner__filled--data owner__data">
                                          <div class="property__owner--img">
                                            <img class="img-fluid userIcon"
                                              src="../../assets/images/contracts/user-icon.svg" alt="User Icon">
                                          </div>
                                          <div
                                            class="d-flex align-items-start justify-flex-start flex-column pr-3 w-80">
                                            <div
                                              class="property__owner--title pb-1 d-flex align-items-end justify-content-between w-100">
                                              <h4>محمد أحمد فهد </h4>
                                              <span class="edit--icon">
                                                <img class="img-fluid cursor-pointer"
                                                  src="../../assets/images/contracts/editPen.svg" alt="Edit Icon">
                                              </span>
                                            </div>
                                            <!-- property__owner--title -->
                                            <div class="owner--status d-flex">
                                              <p>
                                                مالك العقار
                                              </p>
                                              <span class="identity--number">
                                                رقم الهوية : 53694488
                                              </span>
                                            </div>
                                          </div>
                                        </div>
                                        <!-- owner__filled--data end -->
                                        <div class="add--owner--btns">
                                          <span v-b-modal.individualOwnerModal class="green--color ml-5">+ إضافة مالك
                                            فرد</span>
                                          <span v-b-modal.facilityOwnerModal class="green--color ">+ إضافة مالك
                                            منشأة</span>
                                        </div>
                                      </div>
                                      <!-- col 6  -->
                                    </div>
                                  </div>
                                  <!-- property__owner--data -->
                                </b-card-text>
                              </b-card-body>
                            </b-collapse>
                            <!-- accordion-2 -->
                          </b-card>
                          <!-- b card 2 address--accordian ends  ملاك العقار-->
                          <b-card no-body class="mb-1 property__details--accordian">
                            <b-card-header header-tag="header" class="p-1" role="tab">
                              <b-button block v-b-toggle.accordion-3 variant="info">تفاصيل العقار <strong
                                  v-if="isVisible" aria-hidden="true" class="arrow--icon hi-angle-up icon"></strong>
                                <strong v-else aria-hidden="true" class="arrow--icon hi-angle-down icon"></strong>
                              </b-button>
                            </b-card-header>
                            <b-collapse id="accordion-3" accordion="my-accordion" role="tabpanel">
                              <b-card-body>
                                <b-card-text>
                                  <div class="custom--row d-flex flex-wrap row">
                                    <div class="col-md-4">
                                      <label for="" class="color--black">استخدام العقار لـ <span
                                          class="color--red compulsory">*</span></label>
                                      <b-form-select v-model="selected" :options="options" class="select-dropdown"
                                        no-caret>
                                        <!-- This slot appears above the options from 'options' prop -->
                                        <template #first>
                                          <b-form-select-option :value="null" disabled no-caret>اختر نوع العقار
                                          </b-form-select-option>
                                        </template>
                                        <!-- These options will appear after the ones from 'options' prop -->
                                        <b-form-select-option value="C">Option C</b-form-select-option>
                                        <b-form-select-option value="D">Option D</b-form-select-option>
                                      </b-form-select>
                                    </div>
                                    <!-- col sm 4 ends 1  -->
                                    <div class="col-md-4">
                                      <label for="" class="color--black">نوع العقار</label>
                                      <b-form-select v-model="selected2" :options="options" class="select-dropdown">
                                        <!-- This slot appears above the options from 'options' prop -->
                                        <template #first>
                                          <b-form-select-option :value="null" disabled>اﺧﺘﺮ ﻧﻮع اﻟﻌﻘﺎر
                                          </b-form-select-option>
                                        </template>
                                        <!-- These options will appear after the ones from 'options' prop -->
                                        <b-form-select-option value="C">Option C</b-form-select-option>
                                        <b-form-select-option value="D">Option D</b-form-select-option>
                                      </b-form-select>
                                    </div>
                                    <!-- col sm 4 ends 2  -->
                                    <div class="col-md-4">
                                      <div>
                                        <label for="datepicker-placeholder4" class="color--black">تاريخ البناء</label>
                                        <b-form-datepicker id="datepicker-placeholder4" placeholder="تاريخ البناء"
                                          locale="en">
                                        </b-form-datepicker>
                                      </div>
                                    </div>
                                    <!-- col sm 4 ends 3  -->
                                    <div class="col-md-4">
                                      <label for="date" class="color--black">رقم العقار</label>
                                      <b-form-select v-model="selected3" :options="options" class="select-dropdown">
                                        <!-- This slot appears above the options from 'options' prop -->
                                        <template #first>
                                          <b-form-select-option :value="null" disabled>رقم العقار</b-form-select-option>
                                        </template>
                                        <!-- These options will appear after the ones from 'options' prop -->
                                        <b-form-select-option value="C">Option C</b-form-select-option>
                                        <b-form-select-option value="D">Option D</b-form-select-option>
                                      </b-form-select>
                                    </div>
                                    <!-- col sm 4 ends 4  -->
                                    <div class="col-md-8 col-xl-4 custom--col d-flex gap-10px">
                                      <div class="col-sm-6 mobile--p-0">
                                        <label for="" class="color--black">إجمالي عدد الطوابق</label>
                                        <b-form-input v-model="text" placeholder="05"></b-form-input>
                                      </div>
                                      <!-- col 6  -->
                                      <div class="col-sm-6 mobile--p-0">
                                        <label for="">وحدة / طابق</label>
                                        <b-form-input v-model="text" placeholder="05"></b-form-input>
                                      </div>
                                      <!-- col 6  -->
                                    </div>
                                    <!-- col sm 4 ends 5  -->
                                    <div class="col-md-4">
                                      <label for="date" class="color--black">أسم العقار</label>
                                      <b-form-select v-model="selected3" :options="options" class="select-dropdown">
                                        <!-- This slot appears above the options from 'options' prop -->
                                        <template #first>
                                          <b-form-select-option :value="null" disabled>أسم العقار</b-form-select-option>
                                        </template>
                                        <!-- These options will appear after the ones from 'options' prop -->
                                        <b-form-select-option value="C">Option C</b-form-select-option>
                                        <b-form-select-option value="D">Option D</b-form-select-option>
                                      </b-form-select>
                                    </div>
                                    <!-- col sm 4 ends 6  -->
                                  </div>
                                  <!-- row 1 ends  -->
                                  <div>
                                    <div class="national--address pb-3">
                                      <h6>
                                        العنوان الوطني
                                      </h6>
                                    </div>
                                  <div class="row">
                                    <div class="col-md-4">
                                      <label for="" class="color--black">المنطقة</label>
                                      <b-form-select v-model="selected" :options="options" class="select-dropdown"
                                        no-caret>
                                        <!-- This slot appears above the options from 'options' prop -->
                                        <template #first>
                                          <b-form-select-option :value="null" disabled no-caret>المنطقة
                                          </b-form-select-option>
                                        </template>
                                        <!-- These options will appear after the ones from 'options' prop -->
                                        <b-form-select-option value="C">Option C</b-form-select-option>
                                        <b-form-select-option value="D">Option D</b-form-select-option>
                                      </b-form-select>
                                    </div>
                                    <!-- col sm 4 ends 1  -->
                                    <div class="col-md-4">
                                      <label for="" class="color--black">المدينة</label>
                                      <b-form-select v-model="selected2" :options="options" class="select-dropdown">
                                        <!-- This slot appears above the options from 'options' prop -->
                                        <template #first>
                                          <b-form-select-option :value="null" disabled>المدينة</b-form-select-option>
                                        </template>
                                        <!-- These options will appear after the ones from 'options' prop -->
                                        <b-form-select-option value="C">Option C</b-form-select-option>
                                        <b-form-select-option value="D">Option D</b-form-select-option>
                                      </b-form-select>
                                    </div>
                                    <!-- col sm 4 ends 2  -->
                                    <div class="col-md-4">
                                      <label for="" class="color--black">أسم الشارع</label>
                                      <b-form-select v-model="selected2" :options="options" class="select-dropdown">
                                        <!-- This slot appears above the options from 'options' prop -->
                                        <template #first>
                                          <b-form-select-option :value="null" disabled>أسم الشارع</b-form-select-option>
                                        </template>
                                        <!-- These options will appear after the ones from 'options' prop -->
                                        <b-form-select-option value="C">Option C</b-form-select-option>
                                        <b-form-select-option value="D">Option D</b-form-select-option>
                                      </b-form-select>
                                    </div>
                                    <!-- col sm 4 ends 3  -->
                                    <div class="col-md-4">
                                      <label for="date" class="color--black">رقم المبني</label>
                                      <b-form-input v-model="text" placeholder="14"></b-form-input>
                                    </div>
                                    <!-- col sm 4 ends 4  -->
                                  </div>
                                  <!-- row 2 ends  -->
                                </div>
                                </b-card-text>
                              </b-card-body>
                            </b-collapse>
                            <!-- accordion-3 -->
                          </b-card>
                          <!-- b card 3  property__details--accordian ends  تفاصيل العقار-->
                          <b-card no-body class="mb-1 units__details--accordian">
                            <b-card-header header-tag="header" class="p-1" role="tab">
                              <b-button block v-b-toggle.accordion-4 variant="info">تفاصيل الوحدات <strong
                                  v-if="isVisible" aria-hidden="true" class="arrow--icon hi-angle-up icon"></strong>
                                <strong v-else aria-hidden="true" class="arrow--icon hi-angle-down icon"></strong>
                              </b-button>
                            </b-card-header>
                            <b-collapse id="accordion-4" accordion="my-accordion" role="tabpanel">
                              <b-card-body>
                                <b-card-text>
                                  <div class="custom--row d-flex flex-wrap row">
                                    <div class="col-md-4">
                                      <label for="" class="color--black">نوع الوحدة</label>
                                      <b-form-select v-model="selected" :options="options" class="select-dropdown"
                                        no-caret>
                                        <!-- This slot appears above the options from 'options' prop -->
                                        <template #first>
                                          <b-form-select-option :value="null" disabled no-caret>نوع الوحدة
                                          </b-form-select-option>
                                        </template>
                                        <!-- These options will appear after the ones from 'options' prop -->
                                        <b-form-select-option value="C">Option C</b-form-select-option>
                                        <b-form-select-option value="D">Option D</b-form-select-option>
                                      </b-form-select>
                                    </div>
                                    <!-- col sm 4 ends 1  -->
                                    <div class="col-md-4">
                                      <label for="" class="color--black">استخدام الوحدة </label>
                                      <b-form-select v-model="selected2" :options="options" class="select-dropdown">
                                        <!-- This slot appears above the options from 'options' prop -->
                                        <template #first>
                                          <b-form-select-option :value="null" disabled>استخدام الوحدة
                                          </b-form-select-option>
                                        </template>
                                        <!-- These options will appear after the ones from 'options' prop -->
                                        <b-form-select-option value="C">Option C</b-form-select-option>
                                        <b-form-select-option value="D">Option D</b-form-select-option>
                                      </b-form-select>
                                    </div>
                                    <!-- col sm 4 ends 2  -->
                                    <div class="col-md-4">
                                      <div>
                                        <label for="datepicker-placeholder5" class="color--black">تاريخ البناء</label>
                                        <b-form-datepicker id="datepicker-placeholder5" placeholder="تاريخ البناء"
                                          locale="en">
                                        </b-form-datepicker>
                                      </div>
                                    </div>
                                    <!-- col sm 4 ends 3  -->
                                    <div class="col-md-6 col-xl-4">
                                      <label for="date" class="color--black">نوع الوحدة</label>
                                      <b-form-select v-model="selected3" :options="options" class="select-dropdown">
                                        <!-- This slot appears above the options from 'options' prop -->
                                        <template #first>
                                          <b-form-select-option :value="null" disabled>نوع الوحدة</b-form-select-option>
                                        </template>
                                        <!-- These options will appear after the ones from 'options' prop -->
                                        <b-form-select-option value="C">Option C</b-form-select-option>
                                        <b-form-select-option value="D">Option D</b-form-select-option>
                                      </b-form-select>
                                    </div>
                                    <!-- col sm 4 ends 4  -->
                                    <div class="col-md-6 col-xl-4">
                                      <label for="date" class="color--black">تشطيب الوحدة</label>
                                      <b-form-select v-model="selected3" :options="options" class="select-dropdown">
                                        <!-- This slot appears above the options from 'options' prop -->
                                        <template #first>
                                          <b-form-select-option :value="null" disabled>تشطيب الوحدة
                                          </b-form-select-option>
                                        </template>
                                        <!-- These options will appear after the ones from 'options' prop -->
                                        <b-form-select-option value="C">Option C</b-form-select-option>
                                        <b-form-select-option value="D">Option D</b-form-select-option>
                                      </b-form-select>
                                    </div>
                                    <!-- col sm 4 ends 5  -->
                                    <div class="col-md-12 col-xl-4 custom--col d-flex gap-10px">
                                      <div class="col-sm-6 mobile--p-0">
                                        <label for="" class="color--black">تشطيب الوحدة</label>
                                        <b-form-input v-model="text" placeholder="مفروشة"></b-form-input>
                                      </div>
                                      <!-- col 6  -->
                                      <div class="col-sm-6 mobile--p-0">
                                        <label for="">حالة الفرش</label>
                                        <b-form-input v-model="text" placeholder="مفروشة بالكامل"></b-form-input>
                                      </div>
                                      <!-- col 6  -->
                                    </div>
                                    <!-- col sm 4 ends 6  -->
                                  </div>
                                  <!-- row 1 ends  -->
                                  <div class="row">
                                    <div class="col-xl-2 col-md-4 col-sm-6 pb-sm-0 pb-3">
                                      <label for="" class="color--black">رقم الوحدة</label>
                                      <b-form-input v-model="text" placeholder="14"></b-form-input>
                                    </div>
                                    <!-- col sm 2 ends 1  -->
                                    <div class="col-xl-2 col-md-4 col-sm-6 pb-sm-0 pb-3">
                                      <label for="" class="color--black">رقم الطابق</label>
                                      <b-form-input v-model="text" placeholder="14"></b-form-input>
                                    </div>
                                    <!-- col sm 2 ends 2  -->
                                    <div class="col-xl-2 col-md-4 col-sm-6  pb-sm-0 pb-3">
                                      <label for="" class="color--black">مساحة الوحدة (متر)</label>
                                      <b-form-input v-model="text" placeholder="200"></b-form-input>
                                    </div>
                                    <!-- col sm 2 ends 3  -->
                                    <div class="col-xl-2 col-md-4 col-sm-6  pb-sm-0 pb-3">
                                      <label for="" class="color--black">طول الوحدة (متر)</label>
                                      <b-form-input v-model="text" placeholder="20"></b-form-input>
                                    </div>
                                    <!-- col sm 2 ends 5  -->
                                    <div class="col-xl-2 col-md-4 col-sm-6  pb-sm-0 pb-3">
                                      <label for="" class="color--black">عرض الوحدة (متر)</label>
                                      <b-form-input v-model="text" placeholder="14"></b-form-input>
                                    </div>
                                    <!-- col sm 2 ends 6  -->
                                    <div class="col-xl-2 col-md-4 col-sm-6  pb-sm-0 pb-3">
                                      <label for="" class="color--black">إرتفاع الوحدة (متر)</label>
                                      <b-form-input v-model="text" placeholder="05"></b-form-input>
                                    </div>
                                    <!-- col sm 2 ends 4  -->
                                  </div>
                                  <!-- row 2 ends  -->
                                </b-card-text>
                              </b-card-body>
                            </b-collapse>
                            <!-- accordion-4 -->
                          </b-card>
                          <!-- b card 4  units__details--accordian ends  تفاصيل الوحدات-->
                        </div>
                      </div>
                    </div>
                    <div class="">
                      <div class="next__btn--div d-flex align-items-center justify-content-end pl-3">
                        <button class="next__btn--button back--button ml-3" @click="tabIndex--">العودة</button>
                        <button class="next__btn--button" @click="tabIndex++">التالي</button>
                      </div>
                    </div>
                  </div>
                  <!-- register--new--property -->
                  <!-- add property ends  -->
                </b-card>
              </b-tab>
              <!-- tab 2 العقارات و الوحدات" end  -->
              <!-- tab 3 أطراف العقد starts -->
              <b-tab title="أطراف العقد">
                <b-card>
                  <!-- add property starts  -->
                  <div class="container mobile--p-0">
                    <div class="add__property--div contract--parties">
                      <div class="accordion" role="tablist">
                        <b-card no-body class="mb-1 address--accordian">
                          <b-card-header header-tag="header" class="p-1" role="tab">
                            <b-button block v-b-toggle.accordion-1 variant="info">معلومات المالك<strong v-if="isVisible"
                                aria-hidden="true" class="arrow--icon hi-angle-up icon"></strong>
                              <strong v-else aria-hidden="true" class="arrow--icon hi-angle-down icon"></strong>
                            </b-button>
                          </b-card-header>
                          <b-collapse id="accordion-1" visible accordion="my-accordion" role="tabpanel">
                            <b-card-body class="pt-0">
                              <b-card-text>
                                <div class="property__owner--data">
                                  <div class="row">
                                    <div class="col-md-6">
                                      <div class="property__owner--title">
                                        <h6>
                                          تم تسجيل مالك العقار من معلومات وثيقة الملكية
                                        </h6>
                                      </div>
                                      <!-- property__owner--title -->
                                      <div class="owner__filled--data owner__data">
                                        <div class="property__owner--img">
                                          <img class="img-fluid userIcon"
                                            src="../../assets/images/contracts/user-icon.svg" alt="User Icon">
                                        </div>
                                        <div class="d-flex align-items-start justify-flex-start flex-column pr-3 w-80">
                                          <div
                                            class="property__owner--title pb-1 d-flex align-items-end justify-content-between w-100">
                                            <h4>محمد أحمد فهد </h4>
                                            <span class="edit--icon">
                                              <img class="img-fluid cursor-pointer"
                                                src="../../assets/images/contracts/editPen.svg" alt="Edit Icon">
                                            </span>
                                          </div>
                                          <!-- property__owner--title -->
                                          <div class="owner--status d-flex">
                                            <p>
                                              مالك العقار
                                            </p>
                                            <span class="identity--number">
                                              رقم الهوية : 53694488
                                            </span>
                                          </div>
                                        </div>
                                      </div>
                                      <!-- owner__filled--data end -->
                                      <div class="add--owner--btns">
                                        <span v-b-modal.individualOwnerModal class="green--color ml-5">+ إضافة مالك
                                          فرد</span>
                                        <span v-b-modal.facilityOwnerModal class="green--color ">+ إضافة مالك
                                          منشأة</span>
                                      </div>
                                    </div>
                                    <!-- col 6  -->
                                  </div>
                                </div>
                                <!-- property__owner--data -->
                              </b-card-text>
                            </b-card-body>
                          </b-collapse>
                          <!-- accordion-2 -->
                        </b-card>
                        <!-- b card 1 address--accordian ends معلومات المالك-->
                        <b-card no-body class="mb-1 address--accordian">
                          <b-card-header header-tag="header" class="p-1" role="tab">
                            <b-button block v-b-toggle.accordion-2 variant="info">معلومات المستأجر<strong
                                v-if="isVisible" aria-hidden="true" class="arrow--icon hi-angle-up icon"></strong>
                              <strong v-else aria-hidden="true" class="arrow--icon hi-angle-down icon"></strong>
                            </b-button>
                          </b-card-header>
                          <b-collapse id="accordion-2" accordion="my-accordion" role="tabpanel">
                            <b-card-body class="pt-0">
                              <b-card-text>
                                <div class="property__owner--data">
                                  <div class="row">
                                    <div class="col-md-6">
                                      <div class="property__owner--title">
                                        <h6>
                                          إضافة مستأجر جديد
                                        </h6>
                                      </div>
                                      <!-- property__owner--title -->
                                      <div class="owner__empty--data owner__data">
                                        <div class="property__owner--img">
                                          <img class="img-fluid userIcon"
                                            src="../../assets/images/contracts/user-icon.svg" alt="User Icon">
                                        </div>
                                        <div class="property__owner--text">
                                          <h4>لا يوجد ملاك حاليين</h4>
                                        </div>
                                      </div>
                                      <!-- owner__empty--data end -->
                                      <div class="add--owner--btns">
                                        <span v-b-modal.individualOwnerModal class="green--color ml-5">+ إضافة مستأجر
                                          فرد</span>
                                        <span v-b-modal.facilityOwnerModal class="green--color ">+ إضافة مستأجر
                                          منشأة</span>
                                      </div>
                                    </div>
                                    <!-- col 6  -->
                                  </div>
                                </div>
                                <!-- property__owner--data -->
                              </b-card-text>
                            </b-card-body>
                          </b-collapse>
                          <!-- accordion-2 -->
                        </b-card>
                        <!-- b card 2 address--accordian ends معلومات المستأجر -->
                      </div>
                    </div>
                  </div>
                  <div class="">
                    <div class="next__btn--div d-flex align-items-center justify-content-end pl-3">
                      <button class="next__btn--button back--button ml-3" @click="tabIndex--">العودة</button>
                      <button class="next__btn--button" @click="tabIndex++">التالي</button>
                    </div>
                  </div>
                  <!-- add property ends  -->
                </b-card>
              </b-tab>
              <!-- tab 3 أطراف العقد end  -->
              <!-- tab 4 مرافقين المستأجر starts -->
              <b-tab title="مرافقين المستأجر " class="tenant--escorts--tab">
                <b-card>
                  <div class="date__picker--row tenant--escorts--row row">
                    <div class="tenant--escorts--col">
                      <label for="date">العلاقة</label>
                      <b-form-select v-model="selected" class="mb-0 select-dropdown" no-caret>
                        <!-- This slot appears above the options from 'options' prop -->
                        <template #first>
                          <b-form-select-option :value="null" disabled no-caret>أبن \ أبنة</b-form-select-option>
                        </template>
                        <!-- These options will appear after the ones from 'options' prop -->
                        <b-form-select-option value="C">عقد سكني</b-form-select-option>
                        <b-form-select-option value="D">عقد تجاري </b-form-select-option>
                      </b-form-select>
                    </div>
                    <!-- col sm 4 end 1  -->
                    <div class="tenant--escorts--col">
                      <label for="date">نوع الهوية<span class="color--red compulsory">*</span></label>
                      <b-form-select v-model="selected" class="mb-0 select-dropdown" no-caret>
                        <!-- This slot appears above the options from 'options' prop -->
                        <template #first>
                          <b-form-select-option :value="null" disabled no-caret>اختر نوع الهوية </b-form-select-option>
                        </template>
                        <!-- These options will appear after the ones from 'options' prop -->
                        <b-form-select-option value="C">عقد سكني</b-form-select-option>
                        <b-form-select-option value="D">عقد تجاري </b-form-select-option>
                      </b-form-select>
                    </div>
                    <!-- col sm 4 end 2  -->
                    <div class="tenant--escorts--col">
                      <label for="date">رقم الهوية <span class="color--red compulsory">*</span></label>
                      <b-form-input v-model="text" placeholder="رقم الهوية "></b-form-input>
                    </div>
                    <!-- col sm 4 end 3  -->
                    <div class="tenant--escorts--col">
                      <div>
                        <label for="datepicker-placeholder6">تاريخ الميلاد</label>
                        <b-form-datepicker id="datepicker-placeholder6" placeholder="تاريخ الميلاد" locale="en">
                        </b-form-datepicker>
                      </div>
                    </div>
                    <!-- col sm 4 end 4 -->
                    <div class="tenant--escorts--col">
                      <div class="date__picker--div next__btn--div pt-4 text-right ">
                        <button class="next__btn--button button--transparent--bg">+ إضافة</button>
                        <!-- <button class="next__btn--button mr-3 next__btn--next--step">الخطوة التالية</button> -->
                      </div>
                    </div>
                    <!-- col sm 4 end 5 -->
                  </div>
                  <!-- row  end-->
                  <div class="date__picker--tenant--escorts cardss d-flex flex-column">
                    <label for="tenant-escorts" class="tenant--escorts--title">مرافقين المستأجر</label>
                    <div class="row">
                      <div class="col-md-4 px-lg-0">
                        <div class="owner__filled--data owner__data d-flex align-items-center">
                          <div class="property__owner--img">
                            <img class="img-fluid userIcon" src="../../assets/images/contracts/user-icon.svg"
                              alt="User Icon">
                          </div>
                          <div class="d-flex align-items-start justify-flex-start flex-column pr-3 w-80">
                            <div
                              class="property__owner--title pb-1 d-flex align-items-end justify-content-between w-100">
                              <h4>محمد أحمد فهد </h4>
                              <span class="edit--icon">
                                <img class="img-fluid cursor-pointer" src="../../assets/images/contracts/editPen.svg"
                                  alt="Edit Icon">
                              </span>
                            </div>
                            <!-- property__owner--title -->
                            <div class="owner--status d-flex">
                              <p>
                                مالك العقار
                              </p>
                              <span class="identity--number">
                                رقم الهوية : 53694488
                              </span>
                            </div>
                          </div>
                        </div>
                        <!-- owner__filled--data end -->
                      </div>
                      <!-- col sm 4 end 1 -->
                      <div class="col-md-4 px-lg-0">
                        <div class="owner__filled--data owner__data d-flex align-items-center">
                          <div class="property__owner--img">
                            <img class="img-fluid userIcon" src="../../assets/images/contracts/user-icon.svg"
                              alt="User Icon">
                          </div>
                          <div class="d-flex align-items-start justify-flex-start flex-column pr-3 w-80">
                            <div
                              class="property__owner--title pb-1 d-flex align-items-end justify-content-between w-100">
                              <h4>محمد أحمد فهد </h4>
                              <span class="edit--icon">
                                <img class="img-fluid cursor-pointer" src="../../assets/images/contracts/editPen.svg"
                                  alt="Edit Icon">
                              </span>
                            </div>
                            <!-- property__owner--title -->
                            <div class="owner--status d-flex">
                              <p>
                                مالك العقار
                              </p>
                              <span class="identity--number">
                                رقم الهوية : 53694488
                              </span>
                            </div>
                          </div>
                        </div>
                        <!-- owner__filled--data end -->
                      </div>
                      <!-- col sm 4 end 2 -->
                      <div class="col-md-4 px-lg-0">
                        <div class="owner__filled--data owner__data d-flex align-items-center">
                          <div class="property__owner--img">
                            <img class="img-fluid userIcon" src="../../assets/images/contracts/user-icon.svg"
                              alt="User Icon">
                          </div>
                          <div class="d-flex align-items-start justify-flex-start flex-column pr-3 w-80">
                            <div
                              class="property__owner--title pb-1 d-flex align-items-end justify-content-between w-100">
                              <h4>محمد أحمد فهد </h4>
                              <span class="edit--icon">
                                <img class="img-fluid cursor-pointer" src="../../assets/images/contracts/editPen.svg"
                                  alt="Edit Icon">
                              </span>
                            </div>
                            <!-- property__owner--title -->
                            <div class="owner--status d-flex">
                              <p>
                                مالك العقار
                              </p>
                              <span class="identity--number">
                                رقم الهوية : 53694488
                              </span>
                            </div>
                          </div>
                        </div>
                        <!-- owner__filled--data end -->
                      </div>
                      <!-- col sm 4 end 3 -->
                    </div>
                  </div>
                  <!-- date__picker--tenant--escorts -->
                  <div class="">
                    <div class="next__btn--div d-flex align-items-center justify-content-end pl-3 mt-5">
                      <button class="next__btn--button back--button ml-3" @click="tabIndex--">العودة</button>
                      <button class="next__btn--button" @click="tabIndex++">التالي</button>
                    </div>
                  </div>
                </b-card>
              </b-tab>
              <!-- tab 4 مرافقين المستأجر end  -->
              <!-- tab 5 البيانات الماليةstarts -->
              <b-tab title="البيانات المالية">
                <b-card>
                  <div class="date__picker--row tenant--escorts--row row mobile--p-0">
                    <div class="col-md-4">
                      <label for="date">رسوم الإيجار السنوي للوحدة <span class="color--red compulsory">*</span></label>
                      <b-form-input v-model="text" placeholder="0.00"></b-form-input>
                    </div>
                    <!-- col sm 4 end 1  -->
                    <div class="col-md-4">
                      <label for="date">تكرار الدفعة</label>
                      <b-form-select v-model="selected" class="mb-0 select-dropdown" no-caret>
                        <!-- This slot appears above the options from 'options' prop -->
                        <template #first>
                          <b-form-select-option :value="null" disabled no-caret>دفعة واحدة </b-form-select-option>
                        </template>
                        <!-- These options will appear after the ones from 'options' prop -->
                        <b-form-select-option value="C">عقد سكني</b-form-select-option>
                        <b-form-select-option value="D">عقد تجاري </b-form-select-option>
                      </b-form-select>
                    </div>
                    <!-- col sm 4 end 2  -->
                  </div>
                  <!-- row  end-->
                  <div class="">
                    <div class="next__btn--div d-flex align-items-center justify-content-end pl-3 mt-4">
                      <button class="next__btn--button back--button ml-3" @click="tabIndex--">العودة</button>
                      <button class="next__btn--button" @click="tabIndex++">التالي</button>
                    </div>
                  </div>
                </b-card>
                <!-- date__picker--tenant--escorts -->
              </b-tab>
              <!-- tab 5 البيانات الماليةend   -->
              <!-- tab 6 الشروط و الأحكام starts -->
              <b-tab title="الشروط و الأحكام">
                <b-card>
                  <div class="date__picker--row tenant--escorts--row row mobile--p-0">
                    <div class="col-md-4">
                      <label for="date">توثيق العقد من خلال</label>
                      <b-form-input v-model="text" placeholder="منصة هكتار' مكتب وساطة'" disabled></b-form-input>
                    </div>
                    <!-- col sm 4 end 1  -->
                    <div class="col-md-4">
                      <label for="date">التاريخ</label>
                      <b-form-input v-model="text" placeholder="19 أكتوبر, 2022" disabled></b-form-input>
                    </div>
                    <!-- col sm 4 end 2  -->
                    <div class="col-md-4">
                      <label for="date">رقم التحويل</label>
                      <b-form-input v-model="text" placeholder="أدخل رقم التحويل"></b-form-input>
                      <span class="input-info-text position-absolute">
                        مصرف الراجحي - SA4880000497608010574382
                      </span>
                    </div>
                    <!-- col sm 4 end 3  -->
                  </div>
                  <!-- row  end-->
                  <div class="row-2 row">
                    <div class="">
                      <div class="next__btn--div d-flex align-items-center justify-content-end pl-3 mt-5 pt-3">
                        <button class="next__btn--button ml-3 back--button " v-on:click="isActive = !isActive">Open
                          Requests
                          Div</button>
                        <button class="next__btn--button back--button ml-3" @click="tabIndex--">العودة</button>
                        <button class="next__btn--button" v-b-modal.endSuccessMsgModal
                          @click="tabIndex++">التالي</button>
                      </div>
                    </div>
                  </div>
                </b-card>
                <!-- date__picker--tenant--escorts -->
              </b-tab>
              <!-- tab 6 الشروط و الأحكام ends   -->
            </b-tabs>
          </b-card>
          <!-- Control buttons-->
          <div class="text-center next-prev--btn">
            <b-button-group class="mt-2">
              <b-button @click="tabIndex--">Previous</b-button>
              <b-button @click="tabIndex++">Next</b-button>
            </b-button-group>
            <div class="text-muted">Current Tab: {{ tabIndex }}</div>
          </div>
        </div>
        <!-- ejar multi steps ends  -->
        <!-- my requests div starts  -->
        <div class="my__requests" v-else>
          <div class="my__requests--content">
            <div class="my__requests--tabs">
              <div class="my__requests--tabs-steps">
                <b-card no-body>
                  <b-tabs card>
                    <b-tab title="البيانات الشخصية" disabled>
                      <b-card>
                        <b-card-text>
                          البيانات الشخصية
                        </b-card-text>
                      </b-card>
                    </b-tab>
                    <b-tab title="العقارات و الوحدات" disabled>
                      <b-card>
                        <b-card-text>
                          العقارات و الوحدات
                        </b-card-text>
                      </b-card>
                    </b-tab>
                    <b-tab title="الطلبات" active>
                      <b-card>
                        <div class="row">
                          <div class="col-md-3">
                            <b-card-text>
                              <div class="contract__documentation">
                                <ul>
                                  <li class="documents active">
                                    طلبات توثيق عقد سكني <span class="counter">3</span>
                                  </li>
                                  <li class="maintenance ">
                                    طلبات الصيانة
                                  </li>
                                  <li class="quotations">
                                    عروض الأسعار
                                  </li>
                                </ul>
                              </div>
                            </b-card-text>
                          </div>
                          <!-- col sm 3  -->
                          <div class="col-md-9 px-sm-0">
                            <div class="card__table">
                              <div class="my__requests--icon active">
                                <img class="img-fluid" src="../../assets/images/contracts/notes.svg"
                                  alt="Edit Notes Icon">
                              </div>
                              <!-- my__requests--icon -->
                              <div class="my__requests--contract--name">
                                <h4 class="title">
                                  عقد سكني - عبد الله محمد
                                </h4>
                              </div>
                              <!-- my__requests--contract--name -->
                              <div class="first__party">
                                <div class="first__party--title">
                                  <span class="first__span">
                                    طرف اول
                                  </span>
                                </div>
                                <!-- first__party--title -->
                                <div class="first__party--name">
                                  <span class="second__span">
                                    صالح يس
                                  </span>
                                </div>
                                <!-- first__party--name  -->
                              </div>
                              <!-- first__party -->
                              <div class="second__party">
                                <div class="second__party--title">
                                  <span class="first__span">
                                    طرف ثاني
                                  </span>
                                </div>
                                <!-- second__party--title -->
                                <div class="second__party--name">
                                  <span class="second__span">
                                    عبدالله محمد
                                  </span>
                                </div>
                                <!-- second__party--name  -->
                              </div>
                              <!-- second__party -->
                              <div class="contract__address">
                                <div class="contract__address--title">
                                  <span class="first__span">
                                    عقار
                                  </span>
                                </div>
                                <!-- contract__address--title -->
                                <div class="contract__address--name">
                                  <span class="second__span">
                                    ٥٤ شارع جرير، الملز، الرياض..
                                  </span>
                                </div>
                                <!-- contract__address--name  -->
                              </div>
                              <!-- contract__address -->
                              <div class="created__date">
                                <div class="created__date--title">
                                  <span class="first__span">
                                    تاريخ الانشاء
                                  </span>
                                </div>
                                <!-- created__date--title -->
                                <div class="created__date--name">
                                  <span class="second__span">
                                    ١٢ يناير ٢٠٢١
                                  </span>
                                </div>
                                <!-- created__date--name  -->
                              </div>
                              <!-- created--date -->
                              <div class="contract__duration">
                                <div class="contract__duration--title">
                                  <span class="first__span">
                                    مدة العقد
                                  </span>
                                </div>
                                <!-- contract__duration--title -->
                                <div class="contract__duration--name">
                                  <span class="second__span">
                                    سنوية
                                  </span>
                                </div>
                                <!-- contract__duration--name  -->
                              </div>
                              <!-- contract__duration -->
                              <div class="start__date">
                                <div class="start__date--title">
                                  <span class="first__span">
                                    تاريخ البداية
                                  </span>
                                </div>
                                <!-- start__date--title -->
                                <div class="start__date--name">
                                  <span class="second__span">
                                    ٢٤ مارس ٢٠٢١
                                  </span>
                                </div>
                                <!-- start__date--name  -->
                              </div>
                              <!-- start__date -->
                              <div class="status__tags">
                                <div class="tag orange-tag">
                                  <span class="tag__circle"></span>
                                  <span>
                                    تحت المراجعة
                                  </span>
                                </div>
                                <!-- tag -->
                              </div>
                              <!-- status__tags -->
                            </div>
                            <!-- card__table 1-->
                            <div class="card__table">
                              <div class="my__requests--icon active">
                                <img class="img-fluid" src="../../assets/images/contracts/notes.svg"
                                  alt="Edit Notes Icon">
                              </div>
                              <!-- my__requests--icon -->
                              <div class="my__requests--contract--name">
                                <h4 class="title">
                                  عقد سكني - عبد الله محمد
                                </h4>
                              </div>
                              <!-- my__requests--contract--name -->
                              <div class="first__party">
                                <div class="first__party--title">
                                  <span class="first__span">
                                    طرف اول
                                  </span>
                                </div>
                                <!-- first__party--title -->
                                <div class="first__party--name">
                                  <span class="second__span">
                                    صالح يس
                                  </span>
                                </div>
                                <!-- first__party--name  -->
                              </div>
                              <!-- first__party -->
                              <div class="second__party">
                                <div class="second__party--title">
                                  <span class="first__span">
                                    طرف ثاني
                                  </span>
                                </div>
                                <!-- second__party--title -->
                                <div class="second__party--name">
                                  <span class="second__span">
                                    عبدالله محمد
                                  </span>
                                </div>
                                <!-- second__party--name  -->
                              </div>
                              <!-- second__party -->
                              <div class="contract__address">
                                <div class="contract__address--title">
                                  <span class="first__span">
                                    عقار
                                  </span>
                                </div>
                                <!-- contract__address--title -->
                                <div class="contract__address--name">
                                  <span class="second__span">
                                    ٥٤ شارع جرير، الملز، الرياض..
                                  </span>
                                </div>
                                <!-- contract__address--name  -->
                              </div>
                              <!-- contract__address -->
                              <div class="created__date">
                                <div class="created__date--title">
                                  <span class="first__span">
                                    تاريخ الانشاء
                                  </span>
                                </div>
                                <!-- created__date--title -->
                                <div class="created__date--name">
                                  <span class="second__span">
                                    ١٢ يناير ٢٠٢١
                                  </span>
                                </div>
                                <!-- created__date--name  -->
                              </div>
                              <!-- created--date -->
                              <div class="contract__duration">
                                <div class="contract__duration--title">
                                  <span class="first__span">
                                    مدة العقد
                                  </span>
                                </div>
                                <!-- contract__duration--title -->
                                <div class="contract__duration--name">
                                  <span class="second__span">
                                    سنوية
                                  </span>
                                </div>
                                <!-- contract__duration--name  -->
                              </div>
                              <!-- contract__duration -->
                              <div class="start__date">
                                <div class="start__date--title">
                                  <span class="first__span">
                                    تاريخ البداية
                                  </span>
                                </div>
                                <!-- start__date--title -->
                                <div class="start__date--name">
                                  <span class="second__span">
                                    ٢٤ مارس ٢٠٢١
                                  </span>
                                </div>
                                <!-- start__date--name  -->
                              </div>
                              <!-- start__date -->
                              <div class="status__tags">
                                <div class="tag orange-tag">
                                  <span class="tag__circle"></span>
                                  <span>
                                    تحت المراجعة
                                  </span>
                                </div>
                                <!-- tag -->
                              </div>
                              <!-- status__tags -->
                            </div>
                            <!-- card__table 2 -->
                            <div class="card__table">
                              <div class="my__requests--icon">
                                <img class="img-fluid" src="../../assets/images/contracts/notes.svg"
                                  alt="Edit Notes Icon">
                              </div>
                              <!-- my__requests--icon -->
                              <div class="my__requests--contract--name">
                                <h4 class="title">
                                  عقد سكني - عبد الله محمد
                                </h4>
                              </div>
                              <!-- my__requests--contract--name -->
                              <div class="first__party">
                                <div class="first__party--title">
                                  <span class="first__span">
                                    طرف اول
                                  </span>
                                </div>
                                <!-- first__party--title -->
                                <div class="first__party--name">
                                  <span class="second__span">
                                    صالح يس
                                  </span>
                                </div>
                                <!-- first__party--name  -->
                              </div>
                              <!-- first__party -->
                              <div class="second__party">
                                <div class="second__party--title">
                                  <span class="first__span">
                                    طرف ثاني
                                  </span>
                                </div>
                                <!-- second__party--title -->
                                <div class="second__party--name">
                                  <span class="second__span">
                                    عبدالله محمد
                                  </span>
                                </div>
                                <!-- second__party--name  -->
                              </div>
                              <!-- second__party -->
                              <div class="contract__address">
                                <div class="contract__address--title">
                                  <span class="first__span">
                                    عقار
                                  </span>
                                </div>
                                <!-- contract__address--title -->
                                <div class="contract__address--name">
                                  <span class="second__span">
                                    ٥٤ شارع جرير، الملز، الرياض..
                                  </span>
                                </div>
                                <!-- contract__address--name  -->
                              </div>
                              <!-- contract__address -->
                              <div class="created__date">
                                <div class="created__date--title">
                                  <span class="first__span">
                                    تاريخ الانشاء
                                  </span>
                                </div>
                                <!-- created__date--title -->
                                <div class="created__date--name">
                                  <span class="second__span">
                                    ١٢ يناير ٢٠٢١
                                  </span>
                                </div>
                                <!-- created__date--name  -->
                              </div>
                              <!-- created--date -->
                              <div class="contract__duration">
                                <div class="contract__duration--title">
                                  <span class="first__span">
                                    مدة العقد
                                  </span>
                                </div>
                                <!-- contract__duration--title -->
                                <div class="contract__duration--name">
                                  <span class="second__span">
                                    سنوية
                                  </span>
                                </div>
                                <!-- contract__duration--name  -->
                              </div>
                              <!-- contract__duration -->
                              <div class="start__date">
                                <div class="start__date--title">
                                  <span class="first__span">
                                    تاريخ البداية
                                  </span>
                                </div>
                                <!-- start__date--title -->
                                <div class="start__date--name">
                                  <span class="second__span">
                                    ٢٤ مارس ٢٠٢١
                                  </span>
                                </div>
                                <!-- start__date--name  -->
                              </div>
                              <!-- start__date -->
                              <div class="status__tags">
                                <div class="tag green-tag">
                                  <span class="tag__circle"></span>
                                  <span>
                                    تحت المراجعة
                                  </span>
                                </div>
                                <!-- tag -->
                              </div>
                              <!-- status__tags -->
                            </div>
                            <!-- card__table 3 green tag -->
                            <div class="card__table">
                              <div class="my__requests--icon">
                                <img class="img-fluid" src="../../assets/images/contracts/notes.svg"
                                  alt="Edit Notes Icon">
                              </div>
                              <!-- my__requests--icon -->
                              <div class="my__requests--contract--name">
                                <h4 class="title">
                                  عقد سكني - عبد الله محمد
                                </h4>
                              </div>
                              <!-- my__requests--contract--name -->
                              <div class="first__party">
                                <div class="first__party--title">
                                  <span class="first__span">
                                    طرف اول
                                  </span>
                                </div>
                                <!-- first__party--title -->
                                <div class="first__party--name">
                                  <span class="second__span">
                                    صالح يس
                                  </span>
                                </div>
                                <!-- first__party--name  -->
                              </div>
                              <!-- first__party -->
                              <div class="second__party">
                                <div class="second__party--title">
                                  <span class="first__span">
                                    طرف ثاني
                                  </span>
                                </div>
                                <!-- second__party--title -->
                                <div class="second__party--name">
                                  <span class="second__span">
                                    عبدالله محمد
                                  </span>
                                </div>
                                <!-- second__party--name  -->
                              </div>
                              <!-- second__party -->
                              <div class="contract__address">
                                <div class="contract__address--title">
                                  <span class="first__span">
                                    عقار
                                  </span>
                                </div>
                                <!-- contract__address--title -->
                                <div class="contract__address--name">
                                  <span class="second__span">
                                    ٥٤ شارع جرير، الملز، الرياض..
                                  </span>
                                </div>
                                <!-- contract__address--name  -->
                              </div>
                              <!-- contract__address -->
                              <div class="created__date">
                                <div class="created__date--title">
                                  <span class="first__span">
                                    تاريخ الانشاء
                                  </span>
                                </div>
                                <!-- created__date--title -->
                                <div class="created__date--name">
                                  <span class="second__span">
                                    ١٢ يناير ٢٠٢١
                                  </span>
                                </div>
                                <!-- created__date--name  -->
                              </div>
                              <!-- created--date -->
                              <div class="contract__duration">
                                <div class="contract__duration--title">
                                  <span class="first__span">
                                    مدة العقد
                                  </span>
                                </div>
                                <!-- contract__duration--title -->
                                <div class="contract__duration--name">
                                  <span class="second__span">
                                    سنوية
                                  </span>
                                </div>
                                <!-- contract__duration--name  -->
                              </div>
                              <!-- contract__duration -->
                              <div class="start__date">
                                <div class="start__date--title">
                                  <span class="first__span">
                                    تاريخ البداية
                                  </span>
                                </div>
                                <!-- start__date--title -->
                                <div class="start__date--name">
                                  <span class="second__span">
                                    ٢٤ مارس ٢٠٢١
                                  </span>
                                </div>
                                <!-- start__date--name  -->
                              </div>
                              <!-- start__date -->
                              <div class="status__tags">
                                <div class="tag red-tag">
                                  <span class="tag__circle"></span>
                                  <span>
                                    تحت المراجعة
                                  </span>
                                </div>
                                <!-- tag -->
                              </div>
                              <!-- status__tags -->
                            </div>
                            <!-- card__table 4 red tag -->
                          </div>
                          <!-- col sm 9  -->
                        </div>
                        <!-- v row  -->
                      </b-card>
                    </b-tab>
                  </b-tabs>
                </b-card>
              </div>
              <!-- my__requests--tabs-steps -->
              <div class="date__picker--div next__btn--div">
                <button class="next__btn--button " v-on:click="isActive = !isActive">Back to Tabs</button>
              </div>
              <!-- next__btn--div -->
            </div>
            <!-- my__requests--tabs ends  -->
          </div>
          <!-- my__requests--content ends -->
        </div>
        <!-- my__requests -->
        <!-- my requests div ends  -->
      </div>
      <!-- container  -->
    </div>
    <!-- ejar__contract -->
    <!-- add owner popup starts  -->
    <!-- facilityOwnerModal starts -->
    <b-modal id="facilityOwnerModal" size="lg" class="add__property--modal" centered hide-footer
      title="إضافة مالك منشأة">
      <div class="add__owner--detail">
        <div class="row">
          <div class="col-md-6">
            <label for="facilityType">نوع المنشأة</label>
            <b-form-select v-model="selected" :options="options" class="select-dropdown" no-caret>
              <!-- This slot appears above the options from 'options' prop -->
              <template #first>
                <b-form-select-option :value="null" disabled no-caret>التجارية</b-form-select-option>
              </template>
              <!-- These options will appear after the ones from 'options' prop -->
              <b-form-select-option value="C">Option C</b-form-select-option>
              <b-form-select-option value="D">Option D</b-form-select-option>
            </b-form-select>
          </div>
          <div class="col-md-6">
            <div>
              <label for="registrationNo">رقم السجل التجاري</label>
              <b-form-input v-model="text" placeholder="56415615656"></b-form-input>
              <span class="success-msg">تم العثور علي رقم السجل التجاري بنجاح</span>
            </div>
          </div>
        </div>
        <div class="date__picker--div next__btn--div">
          <button class="next__btn--button">+ إضافة المالك</button>
        </div>
      </div>
    </b-modal>
    <!-- facilityOwnerModal starts -->
    <!-- individualOwnerModal starts  -->
    <b-modal id="individualOwnerModal" size="lg" class="add__property--modal" centered hide-footer
      title="إضافة مالك فرد">
      <div class="add__owner--detail">
        <div class="row">
          <div class="col-md-6">
            <label for="facilityType">صفة الطرف</label>
            <b-form-select v-model="selected" :options="options" class="select-dropdown" no-caret>
              <!-- This slot appears above the options from 'options' prop -->
              <template #first>
                <b-form-select-option :value="null" disabled no-caret>مستأجر</b-form-select-option>
              </template>
              <!-- These options will appear after the ones from 'options' prop -->
              <b-form-select-option value="C">Option C</b-form-select-option>
              <b-form-select-option value="D">Option D</b-form-select-option>
            </b-form-select>
          </div>
          <!-- col 6 ends 1 -->
          <div class="col-md-6">
            <label for="facilityType">نوع الهوية</label>
            <b-form-select v-model="selected" :options="options" class="select-dropdown" no-caret>
              <!-- This slot appears above the options from 'options' prop -->
              <template #first>
                <b-form-select-option :value="null" disabled no-caret>نوع الهوية</b-form-select-option>
              </template>
              <!-- These options will appear after the ones from 'options' prop -->
              <b-form-select-option value="C">Option C</b-form-select-option>
              <b-form-select-option value="D">Option D</b-form-select-option>
            </b-form-select>
          </div>
          <!-- col 6 ends 2 -->
          <div class="col-md-6">
            <div>
              <label for="datepicker-placeholder7">تاريخ الميلاد</label>
              <b-form-datepicker id="datepicker-placeholder7" placeholder="تاريخ الميلاد" locale="en">
              </b-form-datepicker>
            </div>
          </div>
          <!-- col 6 ends 3 -->
          <div class="col-md-6">
            <div>
              <label for="registrationNo">رقم الهوية</label>
              <b-form-input v-model="text" placeholder="رقم الهوية" class="custom-select select-dropdown">
              </b-form-input>
            </div>
          </div>
          <!-- col 6 ends 4 -->
          <div class="col-md-6">
            <div>
              <label for="registrationNo">رقم التلفون</label>
              <b-form-input v-model="text" placeholder="رقم التلفون" class="custom-select select-dropdown">
              </b-form-input>
            </div>
          </div>
          <!-- col 6 ends 5 -->
          <div class="col-md-6">
            <div>
              <label for="registrationNo">البريد الإلكتروني</label>
              <b-form-input v-model="text" placeholder="البريد الإلكتروني" class="custom-select select-dropdown">
              </b-form-input>
            </div>
          </div>
          <!-- col 6 ends 6 -->
        </div>
        <div class="date__picker--div next__btn--div">
          <button class="next__btn--button" v-b-modal.successMsgModal>+ إضافة المالك</button>
          <button class="next__btn--button mr-3" v-b-modal.errorMsgModal>error msg</button>
        </div>
      </div>
    </b-modal>
    <!-- individualOwnerModal ends -->
    <!-- successMsgModal starts -->
    <b-modal id="successMsgModal" size="lg" class="add__property--modal" centered hide-footer hide-header>
      <div class="add__owner--detail">
        <div class="">
          <div class="success--msg">
            <div class="check-mark-icon">
              <span class="hi-check icon"></span>
            </div>
            <div class="success-msg-text">
              <h5>
                تم العثور علي بيانات المالك !
              </h5>
              <p>
                تم العثور علي بيانات المالك بنجاح
              </p>
            </div>
          </div>
        </div>
      </div>
    </b-modal>
    <!-- successMsgModal ends -->
    <!-- errorMsgModal starts -->
    <b-modal id="errorMsgModal" size="lg" class="add__property--modal" centered hide-footer hide-header>
      <div class="add__owner--detail">
        <div class="">
          <div class="success--msg error--msg">
            <div class="check-mark-icon">
              <span class="hi-cross icon"></span>
            </div>
            <div class="success-msg-text">
              <h5>
                لم يتم العثور علي بيانات المالك !
              </h5>
              <p>
                لم يتم العثور علي بيانات المالك من فضللك أدخل عنوان المالك
              </p>
            </div>
            <div class="px-5">
              <b-form-input v-model="text" placeholder="العنوان الوطني" class="custom-select select-dropdown disabled"
                disabled>
              </b-form-input>
            </div>
          </div>
        </div>
        <div class="date__picker--div next__btn--div text-center">
          <button class="next__btn--button" v-b-modal.successMsgModal>+ إضافة العنوان</button>
        </div>
      </div>
    </b-modal>
    <!-- errorMsgModal ends -->
    <!-- endSuccessMsgModal starts -->
    <b-modal id="endSuccessMsgModal" size="lg" class="add__property--modal" centered hide-footer hide-header>
      <div class="add__owner--detail">
        <div class="">
          <div class="success--msg">
            <div class="check-mark-icon">
              <span class="hi-check icon"></span>
            </div>
            <div class="success-msg-text">
              <h5>
                تم إرسال طلبك بنجاح
              </h5>
              <p class="w-75 m-auto pb-4">
                تم إرسال طلبك بنحاج, سيتم إشعارك بتأكيد التوثيق بعد مراجعة البيانات و تأكيد الدفع
                شكرا لإستخدامك منصة هكتار
              </p>
            </div>
            <div class="date__picker--div next__btn--div">
              <button class="next__btn--button">الذهاب إلي طلباتي</button>
              <button class="next__btn--button mr-3 button--transparent--bg">الرجوع إلي الرئيسية</button></div>
          </div>
        </div>
      </div>
    </b-modal>
    <!-- endSuccessMsgModal ends -->
    <!-- add owner popup ends  -->
    <b-container class="message-container">
      <div>
        <b-card class="contract__detail--card" title="" sub-title="">
          <b-card-text>
            <div class="contracts contract__residential">
              <div class="contract__residential--box d-flex justify-content-between">
                <div>
                  <div class="d-flex">
                    <div class="contract__box--icon">
                      <img src="../../assets/images/contracts-svg.svg" alt="Contracts Image" />
                    </div>
                    <!-- contract__box--icon -->
                    <div class="contract__box--text">
                      <p class="mb-1 font-weight-bold black-text contract__box--text--title">
                        توثيق العقود في منصة إيجار
                      </p>
                    </div>
                    <!-- contract__box--text -->
                  </div>
                  <p class="font-12 gray-color lease--text">
                    يمكنك الان توثيق عقود الإيجار التجارية في منصة إيجار من خلال
                    هكتار
                  </p>
                </div>
                <div class="contract__box--price d-flex align-items-center">
                  <p class="contracts__green--color font-weight-bold font-1_3-rem">
                    {{ servicePrice }} ريال
                  </p>
                </div>
                <!-- contract__box--price -->
              </div>
              <!-- contract__residential--box -->
            </div>
            <!-- contract__residential -->
          </b-card-text>
          <b-card-text>
            <div class="contracts contract__residential--second-para pt-3">
              <div class="contract__residential--box d-flex justify-content-between">
                <div class="">
                  <div class="contract__box--text">
                    <p class="mb-1 font-weight-bold black-text">
                      لتوثيق عقد إيجار. الرجاء إرسال المستندات التالية عبر
                      الواتساب:
                    </p>
                  </div>
                  <!-- contract__box--text -->
                  <div class="contract__send--docs--ul">
                    <ul>
                      <li class="font-12 font-weight-bold gray-color">
                        <img src="../../assets/images/contracts/tick-mark.png" alt="Tick Mark Icon" class="ml-2" />صورة
                        هوية المالك
                      </li>
                      <li class="font-12 font-weight-bold gray-color">
                        <img src="../../assets/images/contracts/tick-mark.png" alt="Tick Mark Icon" class="ml-2" />
                        صورة هوية المستأجر
                      </li>
                      <li class="font-12 font-weight-bold gray-color">
                        <img src="../../assets/images/contracts/tick-mark.png" alt="Tick Mark Icon" class="ml-2" />
                        صورة الصك.
                      </li>
                    </ul>
                  </div>
                  <!-- contract__send--docs--ul -->
                </div>
                <div class="contract__second--para--icon">
                  <img src="../../assets/images/contracts/ejar-logo.png" alt="Ejar Logo" />
                  <img src="../../assets/images/logo.svg" alt="Hectar Logo" />
                </div>
                <!-- contract__box--icon -->
              </div>
              <!-- contract__residential--box -->
            </div>
            <!-- contract__residential -->
          </b-card-text>
          <div class="whatsapp--call--btn">
            <b-button @click="routeToWhatsApp" variant="outline-success">
              515 0092 050</b-button>
          </div>
          <!-- "whatsapp--call--btn -->
          <!-- <b-link href="#" class="card-link">Another link</b-link> -->
        </b-card>
      </div>
    </b-container>
  </div>
  <!-- conversations-container -->
</template>
<script>
  import Slick from 'vue-slick';
  // MyCarrousel.vue
  import 'slick-carousel/slick/slick.css';
  export default {
    name: 'RentContract',
    components: {
      Slick
    },
    data() {
      return {
        isActive: false,
        addProperty: false,
        tabIndex: 1,
        slide: 0,
        slide1: 0,
        slide2: 0,
        slide3: 0,
        slide4: 0,
        slide5: 0,
        sliding: null,
        selected: null,
        selected1: null,
        selected2: null,
        selected3: null,
        selected4: null,
        isVisible: true,
        slickOptions: {
          slidesToShow: 3,
          rtl: true,
          prevArrow: false,
          dots: true,
          nextArrow: false,
          lazyLoad: 'ondemand',
          infinit: false,
          autoplay: true,
    autoplaySpeed: 1000,
          responsive: [{
              breakpoint: 1200,
              settings: {
                slidesToShow: 2
              }
            },
            {
              breakpoint: 1024,
              settings: {
                slidesToShow: 2
              }
            },
            {
              breakpoint: 992,
              settings: {
                slidesToShow: 2
              }
            },
            {
              breakpoint: 768,
              settings: {
                slidesToShow: 2
              }
            },
            {
              breakpoint: 575,
              settings: {
                slidesToShow: 1
              }
            },
            {
              breakpoint: 480,
              settings: {
                slidesToShow: 1
              }
            }
          ]
        },
        text: '',
        options: [{
            value: 'A',
            text: 'Option A'
          },
          {
            value: 'B',
            text: 'Option B'
          }
        ],
        accorionText: `
          Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry
          richardson ad squid. 3 wolf moon officia aute, non cupidatat skateboard dolor
          brunch. Food truck quinoa nesciunt laborum eiusmod. Brunch 3 wolf moon
          tempor, sunt aliqua put a bird on it squid single-origin coffee nulla
          assumenda shoreditch et. Nihil anim keffiyeh helvetica, craft beer labore
          wes anderson cred nesciunt sapiente ea proident. Ad vegan excepteur butcher
          vice lomo. Leggings occaecat craft beer farm-to-table, raw denim aesthetic
          synth nesciunt you probably haven't heard of them accusamus labore VHS.
        `,
        file1: null,
      }
    },
    computed: {
      servicePrice() {
        return this.$route.query.price ?? 395
      }
    },
    methods: {
      routeToWhatsApp() {
        window.open(`https://api.whatsapp.com/send?phone=966551234987`, '_blank')
      },
      onSlideStart() {
        this.sliding = true
      },
      onSlideEnd() {
        this.sliding = false
      },
      // for slick slider 
      next() {
        this.$refs.slick.next();
      },
      prev() {
        this.$refs.slick.prev();
      },
      reInit() {
        // Helpful if you have to deal with v-for to update dynamic lists
        this.$nextTick(() => {
          this.$refs.slick.reSlick();
        });
      },
      // Events listeners
      handleAfterChange(event, slick, currentSlide) {
        console.log('handleAfterChange', event, slick, currentSlide);
      },
      handleBeforeChange(event, slick, currentSlide, nextSlide) {
        console.log('handleBeforeChange', event, slick, currentSlide, nextSlide);
      },
      handleBreakpoint(event, slick, breakpoint) {
        console.log('handleBreakpoint', event, slick, breakpoint);
      },
      handleDestroy(event, slick) {
        console.log('handleDestroy', event, slick);
      },
      handleEdge(event, slick, direction) {
        console.log('handleEdge', event, slick, direction);
      },
      handleInit(event, slick) {
        console.log('handleInit', event, slick);
      },
      handleReInit(event, slick) {
        console.log('handleReInit', event, slick);
      },
      handleSetPosition(event, slick) {
        console.log('handleSetPosition', event, slick);
      },
      handleSwipe(event, slick, direction) {
        console.log('handleSwipe', event, slick, direction);
      },
      handleLazyLoaded(event, slick, image, imageSource) {
        console.log('handleLazyLoaded', event, slick, image, imageSource);
      },
      handleLazyLoadError(event, slick, image, imageSource) {
        console.log('handleLazeLoadError', event, slick, image, imageSource);
      },
    },
  }
</script>
<style lang="scss" scoped>
  @import '@/styles/contracts/contracts.scss';
</style>